.page-template-page-blog,
.archive{
  .container{
    .main-post{
      height: 500px;  
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      position: relative;
      padding: 100px;
      @media (max-width: $small){
        padding: 35px;
      }
      &:hover{
        img{
          transform: scale(1.1);
        }
      }      
      .post-heading{
        width: 50%;
        @media (max-width: $small){
          width: 100%;
          text-align: center;
        }
        h1{
          color: white;
          margin-bottom: 20px;
        }
        p{
          color: white;
          margin-bottom: 20px;         
          display: -webkit-box;            
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; 
          @media (max-width: $small){
            text-align: center;
          }
        }
      }
      a.link-img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .feat-img{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          padding: 20px;
          box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          overflow: hidden;
          div{
            overflow: hidden;
            position: relative;
            height: 100%;
            width: 100%;
            border-radius: 10px;
          }
          span{
            position: absolute;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            top: 20px;
            left: 20px;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
            border-radius: 10px;
            z-index: 1;
          }
          img{          
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            transition: all .3s $ease1;
          }
        }      
      }      
    }
    .filters{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 2rem;
      margin: 85px 0 20px 0;
      padding-bottom: 30px;
      border-bottom: 1px solid #D9D9D9;
      height: 100%;
      @media (max-width: $small){
        justify-content: center;
      }
      span{
        @include font-size(1.125rem);
        font-weight: 500;
        color: $black;
        cursor: pointer;
        height: 100%;
        position: relative;
        &:hover{
          color: $orange;
          &:after{
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: #DC5F2B;
            top: 0;
            left: 0;
            margin-top: 45px;
            display: block;
          }
        }
        &.active{
          color: $orange;
          font-weight: 700;                    
          &:after{
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: #DC5F2B;
            top: 0;
            left: 0;
            margin-top: 45px;
            display: block;
          }
        }
      }
    }
    .articles-list{
      display: flex;
      // column-gap: 1.5rem;
      row-gap: 1.5rem;
      flex-flow: wrap;
      margin-top: 1.5rem;
      .article{
        flex-basis: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
        border-radius:10px;        
        transition: all .3s $ease1;
        @media (max-width: $small){
          flex-basis: 100%;
        }
        @media (max-width: $xsmall){
          flex-basis: 100%;
        }
        &:hover{
          box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.40);
          img{
            transform: scale(1.1);
          }
        }
        a{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px;
          width: 100%;
          column-gap: 2rem;
          @media (max-width: $xsmall){
            flex-direction: column;
            justify-content: center;
          }
          div{
            position: relative;
            display: flex;
            flex-direction: column;
            // overflow: hidden;
            // border-top-left-radius:10px;
            // border-top-right-radius:10px;
            overflow: hidden;
            width: 100%;
            @media (max-width: $xsmall){
              text-align: center;
            }
            @media (max-width: $xsmall){
              margin-top: 20px;
            }            
            &:last-of-type{              
              border-radius:10px;
              max-width: 300px;
              @media (max-width: $xsmall){
                max-width: 100%;
              }
            }
          }
          .excerpt{
            @include font-size(1rem);
            color: rgba($black, .8);
            margin-bottom: 20px;
            display: -webkit-box;            
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media (max-width: $xsmall){
              text-align: center;
              margin-bottom: 30px;
              // padding-bottom: 20px;
            }
          }
        }         
        span{
          margin: 10px 0;
          p{
            margin: 0 20px 0 0;
            @include font-size(0.75rem);
            font-weight: 600;
            display: inline-flex;
          }          
        }
        h5{
          margin-bottom: 10px;
        }
        img{          
          width: 300px;
          height: 100%;
          object-fit: cover;
          border-radius:10px;
          max-height: 200px;
          transition: all .3s $ease1;        
          @media (max-width: $xsmall){
            width: 100%;            
          }  
        }
        .date{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          bottom: 0;
          width: 100%;
          @media (max-width: $xsmall){
            justify-content: center;            
          }
          p{
            @include font-size(0.75rem);
            font-weight: 600;
            color: $main;
            &:nth-of-type(1){
              &:after{
                content: ".";
                margin: 0 10px;
                color: $black;                
              }
            }
            &:nth-of-type(2){
              color: $orange;
              display: flex;
              align-items: center;
              flex-direction: row;
              svg{
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .page-numbers{
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;
      @media (max-width: $xsmall){
        justify-content: center;
      }
      li{
        span[data-url]{
          @include font-size(1.125rem);
          font-weight: 500;
          color: $black;
          cursor: pointer;
        }
        span{                    
          &.current{
            font-weight: 700;
            text-decoration: underline;
            color: $orange;
          }
        }
      }
    }
  }
}