.home{
  .hero{
    width: 100%;               
    &>div{
      display: flex;
      align-items: center;
      justify-content: center;      
      @media (max-width: $small){
        flex-direction: column;
      }
      div{
        width: 50%;
        @media (max-width: $small){
          width: 100%;
        }
        img{
          width: 100%;
          height: auto;
        }
        button{
          &:first-of-type{
            margin-right: 10px;
          }
        }
      }
    }
    h1{
      margin-bottom: 40px;
      @media (max-width: $small){
        width: 100%;
        text-align: center;
      }
    }
    p{
      margin-bottom: 40px;
      @media (max-width: $small){
        text-align: center;
      }
    }    
    .btn-wrapper{
      width: 100%;
      @media (max-width: $small){
        text-align: center;
      }
    }
  }
}