footer{
  display: flex;
  flex-direction: column;  
  .socials{
    padding: 85px 0;
    &>div{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;      
      @media (max-width: $small){
        flex-direction: column;
      }
    }  
    p{
      @media (max-width: $small){
        text-align: center;
      }
    }
    a{
      margin-top: 20px;
      display: inline-flex;
      transition: transform .3s $ease1;
      &:hover{
        
        transform: scale(1.1);
        
      }
      img{
        width: 50px;
        height: auto;
        margin: 0 10px;        
        &:hover{
          transform: scale(1.05);
        }
      }
      &:last-of-type{
        img{
          margin-right: 0;
        }
      }
    }      
  }
  .updates{    
    padding: 85px 0;
    background-color: $sectionGrey;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
    form{
      margin-top: 40px;
      border: 1px solid $orange;
      border-radius: 50px;
      padding: 0;
      input{
        border: none;
        background-color: transparent;        
        padding: 10px 30px;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.375rem;        
        outline: none;
        color: $orange;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $orange;
          opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $orange;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $orange;
        }
      }
      button{
        padding: 10px 30px;
        border-color: $orange;
        background-color: $orange;
        margin: 0;
        cursor: pointer;
        font-weight: 600;
        &:hover{
          background-color: transparent;
          color: $orange;
        }
      }
    }
  }
  .main{
    // padding: 85px 0;
    color: white;
    background-color: $black;
    padding: 10px 0;
    .container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $small){
        flex-direction: column;
      }
    }
    img{
      width: 150px;
      height: auto;
    }
    nav{
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: $small){
        flex-direction: column;
      }
      ul{
        display: flex;
        flex-direction: row;
        li{
          margin: 0 20px;
          
          &.current-menu-item{
            a{
              color: $main;
            }
          }
          a{
            @include font-size(1.125rem);
            font-weight: 500;
            color: white;
            &:hover{
              color: $main;
            }
          }
        }        
      }
      button{
        margin-left: 20px;
        &:hover{
          border-color: white;
          a{
            color: white;
          }
        }
        @media (max-width: $small){
          margin: 0 auto;
          margin-top: 10px;
        }
      }
    }
  }  
  
}

.page-template-page-blog{
  .socials{
    div{
      @media (max-width: $small){
        text-align: center;
      }
    }
    h3{
      margin-bottom: 20px;      
    }
    a{
      @media (max-width: $small){
        margin-bottom: 50px;
      }
      &:first-of-type{
        img{
          margin-left: 0;          
        }        
      }      
    }    
    .blog-footer-img{
      width: 50%;
      @media (max-width: $small){
        width: 100%;
      }
      img{
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}