.page-template-page-about{  
  .team{
    background-color: $sectionGrey;
    h2{
      margin-bottom: 80px;
      text-align: center;
      width: 100%;
    }
    .cards{
      display: flex;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      flex-flow: wrap;
      .card{
        flex-basis: calc(33.33% - .5rem);
        flex-grow: 1;
        display: flex;
        background-color: white;
        flex-direction: column;
        // padding-bottom: 10px;
        &:hover{
          .wrp{
            .content{
              opacity: 1;
              visibility: visible;
            }
          }
        }
        @media (max-width: $xsmall){
          flex-basis: calc(100%);
        }
        img{
          width: 100%;
          height: auto;
        }
        h3{
          @include font-size(1.5rem);
          line-height: 1.829rem;
          text-align: center;
          margin: 20px 0 5px 0;
        }
        .wrp{
          position: relative;
        }
        p{          
          text-align: center;
          margin-bottom: 20px;
          @include font-size(1.125rem);          
          &.content{
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            left: 0;
            top: 0;
            margin-bottom: 0;
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;

            transition: all .3s $ease1;
          }
        }
      }
    }
  }
}