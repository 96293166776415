.home{
  .features{
    &>div{
      position: relative;
      display: flex;
      align-items: start;
      justify-content: center;   
      flex-direction: row;   
      @media (max-width: $small){
        flex-direction: column;
      }
      div{
        width: 50%;
        position: relative;
        @media (max-width: $small){
          width: 75%;
          margin: 0 auto;
        }
        @media (max-width: $xsmall){
          width: 100%;
        }
        .content-wrapper{
          width: 100%;
          position: relative;
          margin: 35px 0;
          transition: all .8s $ease1;
          -webkit-tap-highlight-color: transparent;
          @media (max-width: $xsmall) {
            margin: 20px 0;
          }
          &.active{
            display: inherit;
            &:before{
              display: none;
            }
            h4{
              color:$orange;
              &:before{
                border-color: $orange;
              }
              &:hover{
                padding-left: 40px;
              }        
            }
            p{
              max-height: 200px;
              visibility: visible;
              opacity: 1;
              padding-bottom: 20px;              
              
            }
          }
          &:last-of-type{
            p{
              &:before{              
                display: none;
              }
            }            
          }
          &:before{
            content: "";
            width: 6px;
            height: 185%;
            background-color: $blue;
            position: absolute;              
            left: 8px;
            top: 10px;   
            transition: all .8s $ease1;
            @media (max-width: $small) {
              left: 15px;
              top: 25px;
            }
            @media (max-width: $xsmall) {
              height: 145%;
            }
          }
          // &:first-of-type{
          //   h4{
          //     &::before{
          //       background: linear-gradient(179.98deg, #DC5F2B 0.54%, #15ACDC 44.56%, #574FB4 99.98%);
          //     }
          //   }
          // }
          &:last-of-type{
            &:before{
              display: none;
            }
            &.active{
              p{
                padding-bottom: 0;
              }
            }            
          }
          h4{
            @include font-size(1.5rem);
            color: $black;
            display: flex;
            align-items: center;
            justify-content: start;          
            padding-left: 40px; 
            margin-bottom: 10px;
            transition: all .8s $ease1;
            cursor: pointer;
            &:hover{
              padding-left: 50px;
            }            
            &:before{
              content: "";
              width: 40px;
              height: 40px;
              left: -10px;
              border: 8px solid $blue;
              border-radius: 50%;
              position: absolute;
              margin-right: 40px;
              background-color: white;
              transition: all .8s $ease1;
              z-index: 1;
              @media (max-width: $small) {
                width: 35px;
                height: 35px;
                left: 0;
              }
            }
          }
          p{
            max-height: 0;
            visibility: hidden;
            opacity: 0;
            padding-left: 40px;
            position: relative;
            transition: max-height .8s $ease1;
            // overflow: hidden;            
            &:before{
              content: "";
              width: 6px;
              height: 185%;
              background-color: $orange;
              position: absolute;              
              left: 8px;
              top: -15px;
              transition: all .8s $ease1;              
              @media (max-width: $small) {
                left: 15px;
                top: -30px;
                // height: 150%;
              }
              @media (max-width: $xsmall) {
                height: 145%;
              }
            }
          }
        }        
        img{
          opacity: 0;
          visibility: hidden;
          position: absolute;
          width: 100%;
          max-height: 350px;
          object-fit: contain;
          height: auto;
          top: 0px;
          left: 0;
          transition: all .8s $ease1;
          @media (max-width: $small) {
            margin-top: 50px;
          }
          &.active{
            opacity: 1;
            visibility: visible;         
            position: relative; 
          }
        }
      }
    }
    .container{
      column-gap: 1rem;
      align-items: center;
    }
  }
}