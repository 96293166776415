// :root{
//   font-size: 16px;
//   line-height: 1.2rem;
// }
*,
*:before,
*:after {
  box-sizing: border-box; 
}

@mixin width($value) {
  @include rfs($value, width);
}

@mixin height($value) {
  @include rfs($value, height);
}

@mixin top($value) {
  @include rfs($value, top);
}

@mixin bottom($value) {
  @include rfs($value, bottom);
}

@mixin left($value) {
  @include rfs($value, left);
}

@mixin right($value) {
  @include rfs($value, right);
}
//MAX—SCREEN
$xxlarge: 160rem; // 2560px
$xlarge: 119.938rem; // 1919.008px
$large: 89.938rem; // 1439.008px
$medium: 85.625rem; // 1370px
$small: 63.938rem; // 1023.008px
$xsmall: 47.938rem; // 767.008px
$xxsmall: 22.563rem; //361.008px


body{
  main{
    .page-main{
      width: 100%;
      .container{
        padding: 0 20px;
        max-width: 85.625rem;
        margin: 0 auto;
        @media (max-width: $medium){
          max-width: $small;
        }
        @media (max-width: $small){
          max-width: 100%;
        }
      }
    }    
  }  
}

// $col_number: 12;
// $gutter: 1.15;
// $max_width: 85;
// $row_margin: 40px;

// $col_width: 100 / $col_number;

// .row {
//   max-width: #{$max_width}rem; // SPECIFIC VALUES.
//   margin-left: auto;
//   margin-right: auto;

//   // @include padding-left($row_margin);
//   // @include padding-right($row_margin);
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-flex-flow: row wrap;
//   -ms-flex-flow: row wrap;
//   flex-flow: row wrap;

//   @include min-screen($xlarge){
//     max-width: 103.75rem;
//   }
//   @include max-screen($medium) {
//     @include padding-left($row_margin);
//     @include padding-right($row_margin);
//   }

//   @include max-screen($xsmall) {
//     @include padding-left($row_margin/3);
//     @include padding-right($row_margin/3);
//   }
// }
// .row .row {
//   margin-left: -#{$gutter/2}rem; // SPECIFIC VALUES
//   margin-right: -#{$gutter/2}rem; // SPECIFIC VALUES
//   padding-left: 0;
//   padding-right: 0;
// }
// .row.expanded {
//   max-width: none;
// }
// .row.collapse > .column,
// .row.collapse > .columns {
//   padding-left: 0;
//   padding-right: 0;
// }
// .row.is-collapse-child,
// .row.collapse > .column > .row,
// .row.collapse > .columns > .row {
//   margin-left: 0;
//   margin-right: 0;
// }
// .column,
// .columns {
//   -webkit-flex: 1 1 0px;
//   -ms-flex: 1 1 0px;
//   flex: 1 1 0px;
//   padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//   padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   min-width: initial;
// }
// .column.row.row,
// .row.row.columns {
//   float: none;
//   display: block;
// }
// .row .column.row.row,
// .row .row.row.columns {
//   padding-left: 0;
//   padding-right: 0;
//   margin-left: 0;
//   margin-right: 0;
// }

// .shrink {
//   -webkit-flex: 0 0 auto;
//   -ms-flex: 0 0 auto;
//   flex: 0 0 auto;
//   max-width: 100%;
// }
// .column.align-top,
// .align-top.columns {
//   -webkit-align-self: flex-start;
//   -ms-flex-item-align: start;
//   align-self: flex-start;
// }
// .column.align-bottom,
// .align-bottom.columns {
//   -webkit-align-self: flex-end;
//   -ms-flex-item-align: end;
//   align-self: flex-end;
// }
// .column.align-middle,
// .align-middle.columns {
//   -webkit-align-self: center;
//   -ms-flex-item-align: center;
//   align-self: center;
// }
// .column.align-stretch,
// .align-stretch.columns {
//   -webkit-align-self: stretch;
//   -ms-flex-item-align: stretch;
//   align-self: stretch;
// }
// .align-right {
//   -webkit-justify-content: flex-end;
//   -ms-flex-pack: end;
//   justify-content: flex-end;
// }
// .align-center {
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
// }
// .align-justify {
//   -webkit-justify-content: space-between;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
// }
// .align-spaced {
//   -webkit-justify-content: space-around;
//   -ms-flex-pack: distribute;
//   justify-content: space-around;
// }
// .align-top {
//   -webkit-align-items: flex-start;
//   -ms-flex-align: start;
//   align-items: flex-start;
// }
// .align-self-top {
//   -webkit-align-self: flex-start;
//   -ms-flex-item-align: start;
//   align-self: flex-start;
// }
// .align-bottom {
//   -webkit-align-items: flex-end;
//   -ms-flex-align: end;
//   align-items: flex-end;
// }
// .align-self-bottom {
//   -webkit-align-self: flex-end;
//   -ms-flex-item-align: end;
//   align-self: flex-end;
// }
// .align-middle {
//   -webkit-align-items: center;
//   -ms-flex-align: center;
//   align-items: center;
// }
// .align-self-middle {
//   -webkit-align-self: center;
//   -ms-flex-item-align: center;
//   align-self: center;
// }
// .align-stretch {
//   -webkit-align-items: stretch;
//   -ms-flex-align: stretch;
//   align-items: stretch;
// }
// .align-self-stretch {
//   -webkit-align-self: stretch;
//   -ms-flex-item-align: stretch;
//   align-self: stretch;
// }

// //XXLARGE
// @for $i from 1 through $col_number {
//   $prev_i: $i - 1;

//   .xxlarge-#{$i} {
//     -webkit-flex: 0 0 #{1% * $col_width * $i};
//     -ms-flex: 0 0 #{1% * $col_width * $i};
//     flex: 0 0 #{1% * $col_width * $i};
//     max-width: #{1% * $col_width * $i};
//   }

//   .xxlarge-offset-#{$prev_i} {
//     margin-left: #{1% * $col_width * $prev_i};
//   }
//   .xxlarge-order-#{$i} {
//     -webkit-order: $i;
//     -ms-flex-order: $i;
//     order: $i;
//   }

//   .xxlarge-up-#{$i} {
//     -webkit-flex-wrap: wrap;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//   }
//   .xxlarge-up-#{$i} > .column,
//   .xxlarge-up-#{$i} > .columns {
//     -webkit-flex: 0 0 #{1% * 100 / $i};
//     -ms-flex: 0 0 #{1% * 100 / $i};
//     flex: 0 0 #{1% * 100 / $i};
//     max-width: #{1% * 100 / $i};
//   }
// }

// .xxlarge-collapse {
//   padding-left: 0;
//   padding-right: 0;
// }

// .xxlarge-collapse > .column,
// .xxlarge-collapse > .columns {
//   padding-left: 0;
//   padding-right: 0;
// }
// .xxlarge-uncollapse > .column,
// .xxlarge-uncollapse > .columns {
//   padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//   padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
// }

// @media screen and (max-width: $xlarge) {
//   //XLARGE
//   @for $i from 1 through $col_number {
//     $prev_i: $i - 1;

//     .xlarge-#{$i} {
//       -webkit-flex: 0 0 #{1% * $col_width * $i};
//       -ms-flex: 0 0 #{1% * $col_width * $i};
//       flex: 0 0 #{1% * $col_width * $i};
//       max-width: #{1% * $col_width * $i};
//     }

//     .xlarge-offset-#{$prev_i} {
//       margin-left: #{1% * $col_width * $prev_i};
//     }
//     .xlarge-order-#{$i} {
//       -webkit-order: $i;
//       -ms-flex-order: $i;
//       order: $i;
//     }

//     .xlarge-up-#{$i} {
//       -webkit-flex-wrap: wrap;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//     .xlarge-up-#{$i} > .column,
//     .xlarge-up-#{$i} > .columns {
//       -webkit-flex: 0 0 #{1% * 100 / $i};
//       -ms-flex: 0 0 #{1% * 100 / $i};
//       flex: 0 0 #{1% * 100 / $i};
//       max-width: #{1% * 100 / $i};
//     }
//   }

//   .xlarge-collapse {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   .xlarge-collapse > .column,
//   .xlarge-collapse > .columns {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   .xlarge-uncollapse > .column,
//   .xlarge-uncollapse > .columns {
//     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   }
// }

// @media screen and (max-width: $large) {
//   //LARGE
//   @for $i from 1 through $col_number {
//     $prev_i: $i - 1;

//     .large-#{$i} {
//       -webkit-flex: 0 0 #{1% * $col_width * $i};
//       -ms-flex: 0 0 #{1% * $col_width * $i};
//       flex: 0 0 #{1% * $col_width * $i};
//       max-width: #{1% * $col_width * $i};
//     }

//     .large-offset-#{$prev_i} {
//       margin-left: #{1% * $col_width * $prev_i};
//     }
//     .large-order-#{$i} {
//       -webkit-order: $i;
//       -ms-flex-order: $i;
//       order: $i;
//     }

//     .large-up-#{$i} {
//       -webkit-flex-wrap: wrap;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//     .large-up-#{$i} > .column,
//     .large-up-#{$i} > .columns {
//       -webkit-flex: 0 0 #{1% * 100 / $i};
//       -ms-flex: 0 0 #{1% * 100 / $i};
//       flex: 0 0 #{1% * 100 / $i};
//       max-width: #{1% * 100 / $i};
//     }
//   }

//   .large-collapse {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   .large-collapse > .column,
//   .large-collapse > .columns {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   .large-uncollapse > .column,
//   .large-uncollapse > .columns {
//     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   }
// }

// // @media screen and (max-width: $largeMax) {
// //   //largeMax
// //   @for $i from 1 through $col_number {
// //     $prev_i: $i - 1;

// //     .largeMax-#{$i} {
// //       -webkit-flex: 0 0 #{1% * $col_width * $i};
// //       -ms-flex: 0 0 #{1% * $col_width * $i};
// //       flex: 0 0 #{1% * $col_width * $i};
// //       max-width: #{1% * $col_width * $i};
// //     }

// //     .largeMax-offset-#{$prev_i} {
// //       margin-left: #{1% * $col_width * $prev_i};
// //     }
// //     .largeMax-order-#{$i} {
// //       -webkit-order: $i;
// //       -ms-flex-order: $i;
// //       order: $i;
// //     }

// //     .largeMax-up-#{$i} {
// //       -webkit-flex-wrap: wrap;
// //       -ms-flex-wrap: wrap;
// //       flex-wrap: wrap;
// //     }
// //     .largeMax-up-#{$i} > .column,
// //     .largeMax-up-#{$i} > .columns {
// //       -webkit-flex: 0 0 #{1% * 100 / $i};
// //       -ms-flex: 0 0 #{1% * 100 / $i};
// //       flex: 0 0 #{1% * 100 / $i};
// //       max-width: #{1% * 100 / $i};
// //     }
// //   }

// //   .largeMax-collapse {
// //     padding-left: 0;
// //     padding-right: 0;
// //   }

// //   .largeMax-collapse > .column,
// //   .largeMax-collapse > .columns {
// //     padding-left: 0;
// //     padding-right: 0;
// //   }
// //   .largeMax-uncollapse > .column,
// //   .largeMax-uncollapse > .columns {
// //     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
// //     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
// //   }
// // }

// @media screen and (max-width: $medium) {
//   //MEDIUM
//   @for $i from 1 through $col_number {
//     $prev_i: $i - 1;

//     .medium-#{$i} {
//       -webkit-flex: 0 0 #{1% * $col_width * $i};
//       -ms-flex: 0 0 #{1% * $col_width * $i};
//       flex: 0 0 #{1% * $col_width * $i};
//       max-width: #{1% * $col_width * $i};
//     }

//     .medium-offset-#{$prev_i} {
//       margin-left: #{1% * $col_width * $prev_i};
//     }
//     .medium-order-#{$i} {
//       -webkit-order: $i;
//       -ms-flex-order: $i;
//       order: $i;
//     }

//     .medium-up-#{$i} {
//       -webkit-flex-wrap: wrap;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//     .medium-up-#{$i} > .column,
//     .medium-up-#{$i} > .columns {
//       -webkit-flex: 0 0 #{1% * 100 / $i};
//       -ms-flex: 0 0 #{1% * 100 / $i};
//       flex: 0 0 #{1% * 100 / $i};
//       max-width: #{1% * 100 / $i};
//     }
//   }

//   .medium-collapse {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   .medium-collapse > .column,
//   .medium-collapse > .columns {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   .medium-uncollapse > .column,
//   .medium-uncollapse > .columns {
//     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   }
// }

// @media screen and (max-width: $small) {
//   //SMALL
//   @for $i from 1 through $col_number {
//     $prev_i: $i - 1;

//     .small-#{$i} {
//       -webkit-flex: 0 0 #{1% * $col_width * $i};
//       -ms-flex: 0 0 #{1% * $col_width * $i};
//       flex: 0 0 #{1% * $col_width * $i};
//       max-width: #{1% * $col_width * $i};
//     }

//     .small-offset-#{$prev_i} {
//       margin-left: #{1% * $col_width * $prev_i};
//     }
//     .small-order-#{$i} {
//       -webkit-order: $i;
//       -ms-flex-order: $i;
//       order: $i;
//     }

//     .small-up-#{$i} {
//       -webkit-flex-wrap: wrap;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//     .small-up-#{$i} > .column,
//     .small-up-#{$i} > .columns {
//       -webkit-flex: 0 0 #{1% * 100 / $i};
//       -ms-flex: 0 0 #{1% * 100 / $i};
//       flex: 0 0 #{1% * 100 / $i};
//       max-width: #{1% * 100 / $i};
//     }
//   }

//   .small-collapse {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   .small-collapse > .column,
//   .small-collapse > .columns {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   .small-uncollapse > .column,
//   .small-uncollapse > .columns {
//     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   }
// }

// @media screen and (max-width: $xsmall) {
//   //XSMALL
//   @for $i from 1 through $col_number {
//     $prev_i: $i - 1;

//     .xsmall-#{$i} {
//       -webkit-flex: 0 0 #{1% * $col_width * $i};
//       -ms-flex: 0 0 #{1% * $col_width * $i};
//       flex: 0 0 #{1% * $col_width * $i};
//       max-width: #{1% * $col_width * $i};
//     }

//     .xsmall-offset-#{$prev_i} {
//       margin-left: #{1% * $col_width * $prev_i};
//     }
//     .xsmall-order-#{$i} {
//       -webkit-order: $i;
//       -ms-flex-order: $i;
//       order: $i;
//     }

//     .xsmall-up-#{$i} {
//       -webkit-flex-wrap: wrap;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//     .xsmall-up-#{$i} > .column,
//     .xsmall-up-#{$i} > .columns {
//       -webkit-flex: 0 0 #{1% * 100 / $i};
//       -ms-flex: 0 0 #{1% * 100 / $i};
//       flex: 0 0 #{1% * 100 / $i};
//       max-width: #{1% * 100 / $i};
//     }
//   }

//   .xsmall-collapse {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   .xsmall-collapse > .column,
//   .xsmall-collapse > .columns {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   .xsmall-uncollapse > .column,
//   .xsmall-uncollapse > .columns {
//     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   }
// }

// @media screen and (max-width: $xxsmall) {
//   //XXSMALL
//   @for $i from 1 through $col_number {
//     $prev_i: $i - 1;

//     .xxsmall-#{$i} {
//       -webkit-flex: 0 0 #{1% * $col_width * $i};
//       -ms-flex: 0 0 #{1% * $col_width * $i};
//       flex: 0 0 #{1% * $col_width * $i};
//       max-width: #{1% * $col_width * $i};
//     }

//     .xxsmall-offset-#{$prev_i} {
//       margin-left: #{1% * $col_width * $prev_i};
//     }
//     .xxsmall-order-#{$i} {
//       -webkit-order: $i;
//       -ms-flex-order: $i;
//       order: $i;
//     }

//     .xxsmall-up-#{$i} {
//       -webkit-flex-wrap: wrap;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//     .xxsmall-up-#{$i} > .column,
//     .xxsmall-up-#{$i} > .columns {
//       -webkit-flex: 0 0 #{1% * 100 / $i};
//       -ms-flex: 0 0 #{1% * 100 / $i};
//       flex: 0 0 #{1% * 100 / $i};
//       max-width: #{1% * 100 / $i};
//     }
//   }

//   .xxsmall-collapse {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   .xxsmall-collapse > .column,
//   .xxsmall-collapse > .columns {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   .xxsmall-uncollapse > .column,
//   .xxsmall-uncollapse > .columns {
//     padding-left: #{$gutter/2}rem; // SPECIFIC VALUES
//     padding-right: #{$gutter/2}rem; // SPECIFIC VALUES
//   }
// }
