.page-template-page-about{
  .services{
    .container{
      display: flex;
      flex-direction: column;
      row-gap: 5rem;
      @media (max-width:$small) {
        row-gap: 5rem;
      }
    }
    h2{
      text-align: center;
    }
    .odd,
    .even{
      display: flex;
      flex-direction: row;      
      align-items: center;
      column-gap: 4rem;
      @media (max-width:$small) {
        flex-direction: column;
      }
      // margin: 5rem 0;
      h3{
        margin-bottom: 20px;
        font-weight: 600;
        @include font-size(1.8rem);
        line-height: 2.2rem;
        @media (max-width:$small) {
          text-align: center;
        }
      }      
      p{
        @include font-size(1.125rem);
        @media (max-width:$small) {
          text-align: center;
        }
      }
      img{
        width: 100%;
        height: auto;
        object-fit: contain;
        @media (max-width:$small) {
          margin-top: 50px;
          max-height: 400px;
        }
      }
      div{
        width: 50%;
        @media (max-width:$small) {
          width: 100%;
        }
      }
    }
    .odd{
      div{
        &:first-of-type{
          order: 2;
          @media (max-width:$small) {
            order: 1;
          }
        }        
        &:last-of-type{
          order: 1;
        }
      }
    }
    .even{
      div{
        &:first-of-type{
          order: 1
        }        
        &:last-of-type{
          order: 2;
        }
      }
    }
  }
}