header{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);  
  .header-wrapper{
    max-width: 85.625rem;
    padding: 0px 10px;
    margin: 0 auto;      
    height: 90px;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;    

    @media (max-width: $medium){
      max-width: $small;
    }
    @media (max-width: $small){
      max-width: 100%;
    }    
    .logo{
      z-index: 3;
      img{
        max-width: 50px;
      }
    }
    nav{
      height: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 3;
      position: relative;        
    
      .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;        
        z-index: 5;
        opacity: 0;
        outline: none;
        cursor: pointer;        
        -webkit-tap-highlight-color: transparent;
        @media (min-width: $small){
          display: none;
        }
      }
      
      .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        position: relative;        
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (min-width: $small){
          display: none;
        }
      }
      
      .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #0e2431;
      }
      
      .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
      }
      
      .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
      }
      
      .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
      }

      input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
      }
      
      input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(45deg);
      }
      
      input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: scaleY(0);
      }
      
      input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
        transform: rotate(-45deg);
      }

      input[type="checkbox"]:checked ~ ul{
        opacity: 1;
      }
      
      ul{
        height: 100%;
        position: relative;
        display: flex;
        background: #fff;
        z-index: 2;
        @media (max-width: $small){
          position: fixed;
          top: 90px;
          left: 0;
          width: 100%;
          height: calc(100% - 90px);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          // display: none;
          z-index: 1;
          visibility: hidden;
          opacity: 0;
        }
        li{
          display: flex;
          align-items: center;
          height: 100%;
          position: relative;
          margin: 0 20px;
          @media (max-width: $small){
            height: initial;
            padding-top: 20px;
          }
          &:last-of-type{
            margin: 0;
          }
          &:after{
            transition: all .3s $ease1;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background-color: white;     
          }
          &:hover{
            &::after{
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 3px;
              background-color: $orange;              
            }
          }
          a{
            font-weight: 500;
            @include font-size(1.125rem);
            color: $black;
            height: 100%;
            display: flex;
            align-items: center;
          }
          &.current-menu-item{
            a{
              font-weight: 700;
              color: $main;
            }
            &:after{
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 3px;
              background-color: $orange;              
            }
          }
        }
      }            
    }
  }  
}