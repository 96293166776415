.single-post{
  .featured{
    position: relative;
    margin-bottom: 20px;
    img{
      width: 100%;
      height: auto;
      max-height: 550px;
      object-fit: cover;
    }
    span{
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .content{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1,p{
        color: white;
        display: block;
      }
      p{
        font-weight: 600;
        @include font-size(0.875rem);
        margin: 20px 0;
      }
    }
  }
  .by{
    display: flex;
    align-items: center;
    justify-content: space-between;    
    @media (max-width: $xsmall){
      flex-direction: column;
    }
    .author{
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: 600;
      margin-top: 50px;
      @include font-size(1.5rem);
      img{
        border-radius: 50%;
        margin-right: 10px;
        width: 72px;
        height: 72px;
      }
    }
    .share{
      display: flex;
      align-items: center;           
      margin-top: 50px;
      span{
        font-weight: 600;      
        @include font-size(1.5rem); 
        margin-right: 10px;
      }
      a{
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
        img{
          width: 32px;
          height: auto;
          margin: 0 10px;
          transition: transform .3s $ease1;
        }
        &:first-of-type{
          img{
            margin-left: 0;
          }          
        }
        &:last-of-type{
          img{
            margin-right: 0;
          }          
        }
      }      
    }
  }  
  .tags{
    margin-top: 100px;    
    .container{
      @media (max-width: $xsmall){
        text-align: center;
      }
    }
    span{      
      background-color: #F8F8F8;
      border-radius: 50px;
      padding: 10px 50px;
      margin: 0 10px;
      display: inline-flex;
      transition: all .3s $ease1;
      @media (max-width: $small){
        margin: 10px;
      }
      &:first-of-type{
        margin-left: 0;
        @media (max-width: $small){
          margin-left: 10px;
        }
      }
      &:last-of-type{
        margin-right: 0;
      }

      &:hover{
        p{
          transform: scale(1.1);
        }        
      }
      p{
        color: #574FB4;
        font-weight: 600;
        @include font-size(1.125rem);
        transition: all .3s $ease1;
      }
    }
  }
} 