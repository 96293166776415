.page-template-page-about{
  .hero{
    .container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      @media (max-width:$small) {
        flex-direction: column;
      }
      div{
        width: 50%;
        @media (max-width:$small) {
          width: 100%;
        }
        &:first-of-type{
          width: 40%;
          @media (max-width:$medium) {
            width: 50%;
          }
          @media (max-width:$small) {
            width: 100%;
          }
        }
        h1{
          margin-bottom: 40px;
          @media (max-width:$small) {
            width: 100%;
            text-align: center;
          }
        }
        p{
          @media (max-width:$small) {
            text-align: center;
          }
        }
        img{
          width: 100%;
          height: auto;
          object-fit: contain;
          max-height: 550px;
        }
      }      
    }
  }
}