.home{  
  .areas{
    width: 100%;
    max-width: 100%;
    background-color: $sectionGrey;
    h2{
      text-align: center;
      display: block;
      margin-bottom: 40px;
    }
    .container{
      div{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 5rem;
        justify-content: center;
        height: 100%;
        // padding: 0 10px;
        // max-width: 85.625rem;
        // margin: 0 auto;
        @media (max-width: $small){
          flex-direction: column;
          row-gap: 4rem;
          align-items: center;
        }
      }
      .scroll-up{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: all .3s $ease1;
        &:hover{
          .icon{
            width: 75px;
            height: 75px;
            img{
              height: 21px;
            }
          }
          h4{
            @include font-size(1.75rem);
          }
          p{
            @include font-size(1.5rem);
          }
        }
      }
      .icon{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95px;
        height: 95px; 
        border-radius: 50%;
        margin: 0 auto;        
        transition: all .3s $ease1;
        img{
          height: 41px;
          width: auto;
          transition: all .3s $ease1;
        }
      }
      h4{
        text-align: center;
        margin-top: 20px;
        transition: all .3s $ease1;
      }
      p{
        text-align: center;
        margin-top: 20px;
        transition: all .3s $ease1;
      }
    }    
  }
}