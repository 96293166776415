.page-template-page-contacts{  
  .faq{
    background-color: $sectionGrey;
    h2{
      margin-bottom: 40px;
    }

    .card{
      background-color: white;
      border-radius: 25px;
      margin-bottom: 1rem;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      pointer-events: all;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;      
      @media (max-width: $small){
        padding: 20px;
      }
      &.open{
        h5{
          color: $orange;
          margin-bottom: 20px;
        }        
      }
      svg{
        width: 26px;
      }
      div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;
      }
      h5{
        // margin-bottom: 10px;
        width: 100%;
        transition: all .3s $ease1;
      }
      p{
        // padding-top: 20px;
        overflow: hidden;
        pointer-events: none;
        @include font-size(1.125rem);
        font-weight: 400;
      }      
    }
  }
}