$ease1: cubic-bezier(.075,.82,.165,1);
$black: #000;
$main: #574FB4;
$orange: #DC5F2B;
$blue: #15ACDC;
$sectionGrey: #F8F8F8;

@import "utils/reset";
@import "~rfs/scss"; 
@import '~sass-mediaqueries/_media-queries';
@import 'grid/grid.scss';
// @import '~@splidejs/splide/dist/css/splide-core.min.css';
@import "fonts.scss"; 

@import 'components/**/**/*.scss'; 