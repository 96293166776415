:root{
  font-size: 16px;
  // font-family: 'Montserrat', sans-serif;
  font-family: 'Poppins', sans-serif;
}
body{
    overflow-x: hidden;
    // font-family: 'Gill Sans', sans-serif;    
    // -webkit-transform: translateZ(0);
    // -webkit-backface-visibility: hidden;
}

h1, h2, h3, h4, h5, p{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    font-style: normal;
}
#smooth-content{
    position: relative;
}
h1{
    font-style: normal;
    font-weight: 700;
    @include font-size(2.625rem);
    line-height: 3.188rem;
    color: $black;
    position: relative;
    display: inline-block;
}
h2{
    font-style: normal;
    font-weight: 700;
    @include font-size(2.25rem);
    line-height: 2.75rem;
    color: $black;

    position: relative;
    display: inline-block;        
}
h3{
    font-style: normal;
    font-weight: 700;
    @include font-size(2rem);
    line-height: 2.438rem;
    color: $black;
    position: relative;    
}
h4{
    font-style: normal;
    font-weight: 700;
    @include font-size(1.25rem);
    line-height: 1.5rem;
    color: $black;
    position: relative;
}
h5{
    font-style: normal;
    font-weight: 700;
    @include font-size(1.5rem);
    line-height: 1.813rem;
    color: $black;
    position: relative;    
}
h6{
    font-style: normal;
    font-weight: 300;
    @include font-size(56px);
    line-height: 84px;
    color: $black;
}
p{
    font-style: normal;
    font-weight: 400;
    @include font-size(1.25rem);
    line-height: 1.375rem;
    color: $black;
    text-align: left;
}

strong{
    font-style: normal;
    font-weight: bolder;
    @include font-size(1.25rem);
    line-height: initial;
    color: $black;
    text-align: left;
}
ul {
    li{
        font-style: normal;
        font-weight: 300;
        @include font-size(1.25rem);
        line-height: 2em;
        color: $black;
        text-align: left;        
    }
}
.section-title{
    margin-bottom: 25px;
    display: flex;
    // align-items: center;    
}
.section-content{
    margin-top: 60px;    
}

strong{
    font-weight: bold;
}

span{    
    font-weight: 500;    
}
label{    
    font-weight: 300;
}
a{    
    font-weight: 500;
    text-decoration: none;
    color: #215383;
}
section{
  padding: 85px 0;
  // padding: 85px 10px;
  // max-width: 85.625rem; 
  // margin: 0 auto;  
  // @media (max-width: $medium){
  //   max-width: $small;
  // }
  // @media (max-width: $small){
  //   max-width: 100%;
  // }
}
.hide-small{
    display: inherit;    
}
.show-small{
    display: none;    
}

// main{
//     height: 100%;
//     &>div{
//         height: 100%;
//     }
// }

button{
  border: 1px solid $main;
  color: white;
  @include font-size(1.125rem);
  font-weight: 600;
  line-height: 1.375rem;
  min-width: 185px;
  transition:  all .5s $ease1;
  border-radius: 50px;
  position: relative;    
  background-color: transparent;
  margin: 10px 0;
  display: inline-block;    
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  span{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $main;
    z-index: -1;
    border-radius: 50px;
    transition:  all .5s $ease1;
  }
  a{
    font-weight: 600;    
    // font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
  }
  &:hover{
    border-color: $main;      
    span{
      background-color: transparent;
    }
    a{
      color: $main;
    }
  }
  &.orange{
    span{
      background-color: $orange;
    }      
  }
  &.orange-border{
    border: 1px solid $orange;
    &:hover{
      span{
        background-color: $orange;
      }
      a{
        color: white;
      }
    }
    a{
      color: $orange;
    }
    span{
      background-color: transparent;
    }
  }    
  a{
    display: block;
    color:white;
    padding: 10px 30px;
  }
}


//IMG ANIMATION
.img-wrapper{
  position: relative;
  overflow: hidden;    
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;                    
  }
  .mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #D2F7FF;
    width: 100%;
  }
}

// @-moz-keyframes spin { 
//   100% { -moz-transform: rotate(360deg); } 
// }
// @-webkit-keyframes spin { 
//   100% { -webkit-transform: rotate(360deg); } 
// }
// @keyframes spin { 
//   100% { 
//     -webkit-transform: rotate(360deg); 
//     transform:rotate(360deg); 
//   } 
// }
.load-screen{
  img{        
    width: 50px;
    height: 50px;
    // animation:spin 1s linear infinite;
  }
}