.single-post{
  .related{
    .related-list{
      display: flex;
      -moz-column-gap: .5rem;
      column-gap: .5rem;
      row-gap: .5rem;
      flex-flow: wrap;
      margin-top: .5rem;
    }    
    hr{
      margin-bottom: 100px;
      background-color: #F8F8F8;
      width: 100%;
      height: 2px;
      border: none;
    }
    h2{
      margin-bottom: 20px;
    }
    .article{
      flex-basis: calc(33% - .5rem);
      background-color: #F8F8F8;
      padding-bottom: 50px;
      &:hover{
        img{
          transform: scale(1.1);
        }
      }
      @media (max-width: $small){
        flex-basis: calc(50% - .5rem);
      }
      @media (max-width: $xsmall){
        flex-basis: 100%;
      }
      div{
        overflow: hidden;
        img{
          width: 100%;
          height: auto;
          transition: all .3s $ease1;          
          max-height: 250px;
          object-fit: cover;
        }
      }
      
      a{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }
      span{
        position: absolute;
        top: 10px;
        left: 10px;        
        p{
          display: inline-flex;
          border-radius: 50px;
          padding: 5px 20px;
          color: white;
          font-weight: 600;
          @include font-size(0.75rem); 
        }
      }
      h5{
        padding: 20px 20px 10px 20px;
      }
      .exc{
        padding: 0 20px;
        @include font-size(1.125rem);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .date{
        position: absolute;
        bottom: -50px;
        width: 100%;
        padding: 10px 20px 20px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;        
        p{
          color: $main;
          font-weight: 600;
          @include font-size(0.75rem); 
          &:last-of-type{
            color: $orange;
          }         
        }        
      }
    }
  }
}