.page-template-page-contacts{
  .hero{
    .container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $small){
        flex-direction: column ;
      }
      h1{
        margin-bottom: 40px;        
      }
      p{
        margin-bottom: 40px;
      }    
      &>div{
        width: 50%;
        @media (max-width: $small){
          width: 100%;
        }
        // &:first-of-type{
        //   order:2
        // }
      }
      img{
        width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 550px;
      }
      .form-wrapper{
        @media (max-width: $small){
          text-align: center;
          margin-bottom: 40px;
        }
        form{
          width: 100%;
          @media (max-width: $small){
            text-align: center;
          }
          div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 1rem;
            row-gap: 1rem;
            &:first-of-type{
              margin-bottom: 1rem;
            }
            @media (max-width: $xsmall){
              flex-direction: column;
            }
          }
          input,
          textarea{
            border: 1px solid $main;
            border-radius: 50px;
            padding: 10px 15px;
            font: inherit;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.371rem;   
            color: $main;
            width: 100%;          
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: $main;
              opacity: 1; /* Firefox */
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $main;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
              color: $main;
            }

            &:focus,
            &:active,
            &:focus-visible{
              outline:none;
              border: 1px solid $orange;
              color: $black;
              font-weight: 600;
              &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #D9D9D9;
                opacity: 1; /* Firefox */
              }
              
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #D9D9D9;
              }
              
              &::-ms-input-placeholder { /* Microsoft Edge */
                color: #D9D9D9;
              }
            }
          }
          textarea{
            border-radius: 25px;
          }
          button{
            padding: 10px 25px;
            background-color: $main;
            font-weight: 600;
            @include font-size(1.125rem);
            margin: 0;
            margin-top: 1rem;
            cursor: pointer;
            &:hover{
              color: $main;
              background-color: transparent;
            }
          }
        }      
      } 
    }    
  }
  .info{
    .container{
      display: flex;
      flex-direction: row;
      align-items: start;
      -moz-column-gap: 5rem;
      column-gap: 5rem;
      justify-content: center;
      @media (max-width: $small){
        flex-direction: column;
      }
      div{
        flex-basis: calc(33.33% - 5rem);
        @media (max-width: $small){
          text-align: center;
          flex-basis: 100%;
          width: 100%;
          margin-bottom: 50px;
        }
        img{
          width: 80px;
          height: auto;
          margin-bottom: 20px;
        }
        span{
          display: block;
        }
        h5{
          margin-bottom: 20px;
        }
        a{
          color: $black;
          display: inline-flex;          
          margin: 0 20px;          
          @media (max-width: $small){
            justify-content: center;
          }
          &:hover{
            img{
              transform: scale(1.1);
            }
          }
          &:first-of-type{
            margin-left: 0;
          }
          &:last-of-type{
            margin-right: 0;
          }
          img{
            width: 40px;
            transition: all .3s $ease1;
          }
        }
        &:first-of-type{
          a{
            margin-top: 20px;
          }
        }
        &:last-of-type{
          a{
            margin: 0 0 20px 0;
            display: flex;
          }
        }
      }      
    }
  }

  .form-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#F5F5F5, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;    
    opacity: 0;
    visibility: hidden;
    div{
      position: relative;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px;
      h2{
        margin-bottom: 20px;
      }
      img{
        height: 250px;
        width: auto;
      }
      .close{
        position: absolute;
        right:10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        &::before,
        &:after{
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #15ACDC;
          border-radius: 50px;
        }
        &:before{
          transform: rotate(45deg);
        }
        &:after{
          transform: rotate(-45deg);        
        }
      }
    }
  }
}