.home{
  .about{
    background-color: $sectionGrey;
    padding: 85px 0;
    margin: 0;
    .container{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (max-width: $small){
        flex-direction: column;        
      }
      div{
        width: 50%;
        @media (max-width: $small){
          width: 100%;
          text-align: center;
        }
        h2{
          margin-bottom: 40px;
        }
        p{
          @media (max-width: $small){
            text-align: center;
          }
        }
        button{
          margin-top: 40px;
        }
        img{
          width: 100%;
          height: auto;
          object-fit: contain;
          max-height: 420px;
          @media (max-width: $small){
            margin-top: 40px;
          }
        }
      }      
    }
  }
}