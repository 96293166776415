.single-post{
  .wysiwyg{
    width: 70%;
    @media (max-width: $small){
      width: 100%;
    }
    margin: 0 auto;
    p{
      line-height: 2rem;
      margin: 40px 0;
    }
    img{
      display: block;
      margin: 20px auto;
      width: 100%;
      height: auto;
    } 
    ul{
      list-style: disc;
      list-style-position: inside;
      margin: 40px 0;
    }
    a{
      text-decoration: underline;
    }
  }
}