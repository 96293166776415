.reviews{
  background-color: $sectionGrey;
  padding: 85px 0;
  margin: 0;
  h2{
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  .cards-wrapper{
    display: flex;
    flex-direction: row;    
    column-gap: .5rem;
    row-gap: .5rem;
    flex-flow: wrap;
    div{
      flex-basis: calc(25% - .5rem);
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      padding: 30px;
      @media (max-width: $small){
        flex-basis: calc(50% - .5rem);
      }
      @media (max-width: $xsmall){
        flex-basis: calc(100%);
      }
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
        img{
          width: 72px;
          height: 72px;
          border-radius: 50%;
        }
      }      
      p{
        text-align: center;
        @include font-size(1rem);
        margin-top: 20px;
      }
    }    
  }
}