.single-post{
  .quote{
    padding: 50px 0;
    background-color: #F8F8F8;      
    margin: 40px 0;
    p,
    span{
      text-align: center;
      margin: 0 auto;
      font-style: italic;
    }
    p{    
      font-weight: 500;      
      @include font-size(1.5rem);
      line-height: 1.829rem;    
      width: 70%;
      @media (max-width: $small){
        width: 100%;
      }
    }
    span{      
      margin: 0 auto;
      width: 100%;
      display: block;
      font-weight: 500;
      @include font-size(1rem);
      margin-top: 20px;      
    }
  }
}