.single-post{
  .full-img{
    margin: 40px 0;
    img{
      max-height: 400px;
      height: auto;
      width: 100%;
      object-fit: cover;      
    }
  }
}