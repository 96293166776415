.tiv-project{
  .container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;  
    @media (max-width: $small){
      flex-direction: column;
    }
  }    
  p{
    @include font-size(1.125rem);
    @media (max-width: $small){
      text-align: center;      
    }
  }  
  h3{
    @media (max-width: $small){
      margin-bottom: 40px;
    }
  }
}