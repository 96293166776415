.page-template-default{
  .page-main{
    padding: 50px 0;
    h1{
      margin-bottom: 50px;
    }
    a{
      text-decoration: underline;
    }
  }
}